<template>
  <div class="page">
      <!--查询框-->
      <div class="search-box section page-header" style="margin-bottom: 1px;height: auto;">
          <el-form ref="searchForm" :inline="true" :model="query" size="medium">
              <el-form-item prop="parkingLotId">
                  <SuggestParking v-model="query.parkingLotId" class="width260" />
              </el-form-item>
              <el-form-item prop="leaseName">
                  <el-input v-model="query.leaseName" placeholder="搜索套餐名称" prefix-icon="el-icon-search"
                      class="width240 margin-right10 margin-left10" />
              </el-form-item>
              <el-form-item prop="activityName">
                  <el-input v-model="query.activityName" placeholder="搜索活动名称"
                      class="width240 margin-right10 margin-left10" />
              </el-form-item>
              <el-form-item prop="activityStatus">
                  <el-select v-model="query.activityStatus" clearable placeholder="请选择活动状态" class="width260">
                      <el-option 
                      v-for="item,index in activeType" 
                      :key="index"
                      :label="item.name"
                      :value="item.value"
                      ></el-option>
                      <!-- <el-option label="未开始" value="0"></el-option>
                      <el-option label="进行中" value="1"></el-option>
                      <el-option label="已结束" value="2"></el-option>
                      <el-option label="手动关闭" value="3"></el-option> -->
                  </el-select>
              </el-form-item>
              
              <el-form-item>
                  <el-button type="primary" @click="doSearch">搜索</el-button>
                  <el-button @click="resetQueryForm">重置</el-button>
              </el-form-item>
          </el-form>
      </div>


      <div class="content-box section main-box">
          <el-button type="primary" @click="goAdd" style="margin-bottom: 10px;">新增</el-button>

          <div>
              <!--        v-loading="isLoading"-->
              <el-table :data="dataList" border class="common-table" size="medium" style="width: 100%" height="60vh">
                  <el-table-column align="center" fixed="left" label="活动名称" prop="activityName" width="120">
                  </el-table-column>
                  <el-table-column align="center" fixed="left" label="车场名称" prop="parkingLotName" width="240">
                  </el-table-column>
                  <el-table-column align="center" fixed="left" label="套餐名称" prop="leaseName" width="120">
                  </el-table-column>
                  <el-table-column align="center" fixed="left" label="状态" prop="status" width="80">
                      <template slot-scope="scope">
                          <span>{{ activeType[scope.row.status].name }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column align="center" label="开始时间" prop="startTime" width="160">
                  </el-table-column>
                  <el-table-column align="center" label="结束时间" prop="endTime" width="160">
                  </el-table-column>
                  <el-table-column align="center" label="活动价格/原价" prop="discountPrice" width="120">
                  </el-table-column>  
                  <el-table-column align="center" label="创建人" prop="createBy" width="120">
                  </el-table-column>  
                  <el-table-column align="center" label="创建时间" prop="createTime" width="160">
                  </el-table-column>  
                  
                  <el-table-column align="center" fixed="right" label="操作" prop="name" width="120px">
                      <template slot-scope="scope">
                          <el-button class="common-text font-size14" size="small" type="text"
                              @click="goDetail(scope.row)">详情
                          </el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </div>


          <div class="pagination-box">
              <el-pagination :current-page="pagination.pageNo" :page-size="pagination.pageSize"
                  :total="pagination.totalSize" background layout="sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange" @current-change="handleCurrentChange">
              </el-pagination>
          </div>
      </div>

  </div>
</template>

<script>
import SuggestParking from "@/components/SuggestParking";
// import Settlement from "@/components/Settlement";
import {  updateVehicleNumPlate, } from "@/api/parkingOrder";
import {
  getLeaseActivityList
} from "@/api/lease"
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import Passage from "@/components/Passage";
import { dateFormat } from "@/utils/utils";
import { doExport } from "@/api/common";


export default {
  name: "parking_order_inside",
  mixins: [BaseMixin, Permission],
  data() {
      const today = dateFormat("yyyy-mm-dd", new Date());
      const beginDate = today;
      // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
      const endDate = today;
      return {
          dataList: [],
          summaryData: {},
          // isLoading: false,
          completeDialogFormVisible: false, //控制完结弹窗
          modifyDialogFormVisible: false, //修改车牌弹窗
          total: "0",
          discountAmountStats: "0",
          receivableAmountStats: "0",
          transactionAmountStats: "0",
          // 完结表单
          completeForm: {
              parkingOrderIntoId: "",
              endTime: "",
              isCalculFee: '0',
          },
          rules: {
              endTime: [
                  { required: true, message: "请选择日期时间", trigger: "change" },
              ],
              isCalculFee: [
                  { required: true, message: "请选择是否有效", trigger: "change" },
              ],
          },
          //修改车牌表单
          modifyForm: {
              parkingOrderIntoId: "",
              parkingSpaceId: "",
              color: "",
              numPlate: "",
              reason: "",
          },
          modifyRules: {
              color: [
                  { required: true, message: "请选择车牌颜色", trigger: "change" },
              ],
              numPlate: [
                  { required: true, message: "请输入车牌号码", trigger: "blur" },
              ],
              reason: [
                  { required: true, message: "请输入修改原因", trigger: "blur" },
                  { max: 100, message: "长度不能超过100个字符", trigger: "blur" },
              ],
          },
          pickerOptions: {
              disabledDate(time) {
                  return time.getTime() < new Date().getTime() - 24 * 3600 * 1000 * 90
                      || time.getTime() > new Date().getTime();
              },
              shortcuts: [
                  {
                      text: "最近一周",
                      onClick(picker) {
                          const end = new Date();
                          const start = new Date();
                          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                          picker.$emit("pick", [start, end]);
                      },
                  },
                  {
                      text: "最近一个月",
                      onClick(picker) {
                          const end = new Date();
                          const start = new Date();
                          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                          picker.$emit("pick", [start, end]);
                      },
                  },
                  {
                      text: "最近三个月",
                      onClick(picker) {
                          const end = new Date();
                          const start = new Date();
                          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                          picker.$emit("pick", [start, end]);
                      },
                  },
              ],
          },
          dialogFormVisible: false, //控制审核弹窗层显示
          photoDialogFormVisible: false,
          activeType: [
              {
                  name:"未开始",
                  value:0
              },
              {
                  name:"进行中",
                  value:1
              },
              {
                  name:"已结束",
                  value:2
              },
              {
                  name:"手动关闭",
                  value:3
              },
              ],
          query: {
              companyName: "", //结算方名称
              parkingLotId: "", //停车场id
              parkingOrderIntoId: "", //订单编号
              parkingOrderType: "", //订单类型
              orderStatus: "", //订单状态
              isArrearage: "", //付款状态
              isPlateNumber: "", //有无车牌
              plateNumber: "", //车牌号id
              parkingLotType: "1", //1-路内查询 0-路外查询
              phoneNumber: "", //手机号
              parkingSpeaceCode: "", //泊位号
              // driveType: "", //驶入驶出状态  0-驶入  1-驶出
              driveType: "0", //时间范围
              timeValues: [beginDate, endDate], //时间
              startTime: "",
              endTime: "",
              isExport: "0", //是否导出
              exportSize: 1, //导出条数
          },
          exportDialogFormVisible: false, //导出弹窗
          form: {
              exportSize: 1,
          },
      };
  },
  components: {
      SuggestParking,
  },
  methods: {
      goAdd(){
          this.$router.push("/business_manage/add_long_way");
      },
      // 新增/编辑套餐
      goDetail(item) {
        sessionStorage.setItem("active",JSON.stringify(item));
          this.$router.push({
              path:"/business_manage/detail_long_way",
              query:{
                  id:item.id
              }
          });
      },
      //重置查询条件
      resetQueryForm() {
          const today = dateFormat("yyyy-mm-dd", new Date());
          const beginDate = today;
          // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
          const endDate = today;
          this.query = {
              companyName: "", //结算方名称
              parkingLotId: "", //停车场id
              parkingOrderIntoId: "", //订单编号
              parkingOrderType: "", //订单类型
              orderStatus: "", //订单状态
              isArrearage: "", //付款状态
              isPlateNumber: "", //有无车牌
              plateNumber: "", //车牌号id
              parkingLotType: "1", //路内查询
              phoneNumber: "", //手机号
              parkingSpeaceCode: "", //泊位号
              // driveType: "", //驶入驶出状态  0-驶入  1-驶出
              driveType: "1", //时间范围
              timeValues: [beginDate, endDate], //时间
              startTime: "",
              endTime: "",
              isExport: "0", //是否导出
              exportSize: 1, //导出条数
          };
          this.query.timeValues = [];
          this.pagination.pageSize = 10;
          this.getNowDate();
          this.doSearch();
      },
      doSearch() {
          this.pagination.pageNo = 1;
          this.search();
      },
      async search() {
                      this.isLoading = true;
                      const params = this.paramFormat(this.query);
                      if (this.query.timeValues != null) {
                          if (this.query.driveType == '') {
                              this.$message.warning('请选择时间范围后重试')
                              return false
                          }
                          params["startTime"] = this.query.timeValues[0];
                          params["endTime"] = this.query.timeValues[1];
                      }
                      const result = await getLeaseActivityList(params);
                      // const res = await getLeaseActivityList({
                      //   pageNo: this.pagination.pageNo,
                      //   pageSize: this.pagination.pageSize,
                      //   userId: "1",
                      // });
                      this.isLoading = false;
                      if (result && result.code === 30 && result.result) {
                          const returnObject = result.returnObject;
                          if (returnObject !== "") {
                              this.dataList = returnObject.records;
                              this.summaryData = returnObject
                              this.pagination.totalSize = returnObject.total;
                              this.pagination.totalPages = returnObject.pages;
                              this.total = returnObject.total;
                              this.form.exportSize = returnObject.total;
                          }
                      }
      },
      //完结弹出窗
      complete(item) {
          this.completeDialogFormVisible = true;
          this.completeForm.parkingOrderIntoId = item;
      },

      //关闭完结弹窗清空表单
      completeDialogBeforeClosed(done) {
          this.$refs.completeForm.resetFields();
          done();
      },

      //修改车牌提交
      modifySubmit() {
          this.$refs.modifyForm.validate((valid) => {
              if (valid) {
                  const parkingOrderIntoId = this.modifyForm.parkingOrderIntoId;
                  const color = this.modifyForm.color;
                  const numPlate = this.modifyForm.numPlate;
                  const reason = this.modifyForm.reason;
                  const parkingSpaceId = this.modifyForm.parkingSpaceId;

                  updateVehicleNumPlate(
                      parkingOrderIntoId,
                      parkingSpaceId,
                      numPlate,
                      color,
                      reason
                  ).then((res) => {
                      if (res && res.code === 30 && res.result) {
                          this.$message({
                              message: "修改车牌成功",
                              type: "success",
                          });
                          this.modifyDialogFormVisible = false;
                          this.doSearch();
                      }
                  });
              } else {
                  console.log("error submit!!");
                  return false;
              }
          });
      },
      //修改车牌弹窗关闭时清理表单
      modifyDialogBeforeClosed(done) {
          this.$refs.modifyForm.resetFields();
          done();
      },
      //关闭修改车牌
      closeModify() {
          this.modifyDialogFormVisible = false;
          this.$refs.modifyForm.resetFields();
      },
      // handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      // },
      // handleCurrentChange(val) {
      //   console.log(`当前页: ${val}`);
      // },
      //修改车牌弹窗
      modifyLicensePlate(parkingOrderIntoId, parkingSpaceId) {
          this.modifyDialogFormVisible = true;
          this.modifyForm.parkingOrderIntoId = parkingOrderIntoId;
          this.modifyForm.parkingSpaceId = parkingSpaceId;
      },
      //导出弹窗
      exportData() {
          this.exportDialogFormVisible = true;
      },
      //确认导出
      confirmExport() {
          const loading = this.$loading({
              lock: true,
              text: "正在导出",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0)",
          });
          const params = this.paramFormat(this.query);
          if (this.query.timeValues.length > 0) {
              params["startTime"] = this.query.timeValues[0];
              params["endTime"] = this.query.timeValues[1];
          }
          params["isExport"] = "1";
          params["exportSize"] = this.form.exportSize;
          // const res = searchIncomeFlow(params);
          // var text = window.location.hostname;
          // console.log(text);
          // window.open("http://172.16.69.129:81" + "/ParkingManagermentController/getEsParkingOrderRecord?" + objectToQueryString(params));
          doExport(
              "/ParkingManagermentController/getEsParkingOrderRecord",
              params,
              "路内停车订单.xlsx"
          );
          loading.close();
          this.exportDialogFormVisible = false;

          // if (res && res.code === 30 && res.result) {
          //   loading.close();
          //   this.exportDialogFormVisible = false;
          // }
      },
      //获取当前时间
      getNowDate() {
          const beginToday = dateFormat("yyyy-mm-dd", new Date());
          const beginDate = beginToday;
          const endToday = dateFormat("yyyy-mm-dd", new Date());
          const endDate = endToday;
          this.query.timeValues = [beginDate, endDate];
      },
  },
  mounted(){
  
  },
};
</script>

<style lang="less" scoped>
.photoShow {
  display: flex;
  flex-direction: row;

  .photo-width {
      /deep/ .el-carousel__container {
          width: 430px;
      }
  }

  .driveInPhoto {
      margin: 0 34px;
  }

  .driveOutPhoto {
      margin: 0 26px;
  }
}
.main-box{
  margin-top: 20px;
}

/deep/ .el-button--text {
  color: #0768FD !important;
}
</style>